// Import styles
import './Scss/ShopLocator.scss';

const MAP_STYLES = [
  {
    featureType: 'all',
    elementType: 'geometry.fill',
    stylers: [
      {
        weight: '2.00',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#9c9c9c',
      },
    ],
  },
  {
    featureType: 'all',
    elementType: 'labels.text',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#7b7b7b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#5dbae9',
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#5dbae9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#070707',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
];

const GMAP = function ($map) {
  
  const self = this;
  let mapReady = false;
  const markers = [];
  const storeLocator = document.getElementById('store-locator');
  const mainColor = getComputedStyle(document.body).getPropertyValue('--color-topic');
  const imgPath = '/typo3conf/ext/is_design/Resources/Public/Images/StoreLocator/';

  this.myPositionIcon = `${imgPath}my-position.svg`;
  this.markerIcon = `${imgPath}marker.svg`;
  this.hoverMarkerIcon = `${imgPath}marker-hover.svg`;
  this.activeMarkerIcon = `${imgPath}marker-active.svg`;
  this.clusterIcon = `${imgPath}cluster.svg`;
  this.activeClusterIcon = `${imgPath}cluster-active.svg`;
  const markerpath = 'M15.2259527,19.8127445 C14.2070021,20.8883035 13.118253,21.8681776 12.0291768,22.735405 C11.6473986,23.0394136 11.2921737,23.3081093 10.9722093,23.5391947 C10.777224,23.6800174 10.6351454,23.77842 10.5547002,23.8320502 C10.2188008,24.0559831 9.78119922,24.0559831 9.4452998,23.8320502 C9.36485462,23.77842 9.22277597,23.6800174 9.0277907,23.5391947 C8.70782632,23.3081093 8.35260143,23.0394136 7.97082322,22.735405 C6.88174698,21.8681776 5.7929979,20.8883035 4.77404732,19.8127445 C1.80240157,16.6760073 0,13.3716045 0,10 C0,4.47715241 4.47715256,0 10,0 C15.5228474,0 20,4.47715241 20,10 C20,13.3716045 18.1975984,16.6760073 15.2259527,19.8127445 Z';

  const svgMarker = {
    path: markerpath,
    fillColor: mainColor,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 1.4,
    anchor: new google.maps.Point(10, 25),
  };
  const svgMarkerActive = {
    path: markerpath,
    fillColor: 'red',
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 1.4,
    anchor: new google.maps.Point(10, 25),
  };
  const svgMarkerHover = {
    path: markerpath,
    fillColor: mainColor,
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 1.4,
    anchor: new google.maps.Point(10, 25),
  };

  const markerIcon = svgMarker;
  const activeMarkerIcon = svgMarkerActive;
  const hoverMarkerIcon = svgMarkerHover;

  var boundsCount = 0;

  this.init = function () {
    this.initMap();
  };

  this.mapReady = function () {
    mapReady = true;
    this.addMarkers();
  };

  this.initMap = function () {
    self.map = new google.maps.Map(document.querySelectorAll('[data-map]')[0], {
      center: {
        lat: 50.4321384, //parseInt(storeLocator.getAttribute('data-start-lat')),
        lng: 6.2948739, //parseInt(storeLocator.getAttribute('data-start-lng')),
      },
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: true,
      scaleControl: true,
      fullscreenControl: false,
      styles: MAP_STYLES,
    });

    const svg = window.btoa(`<svg fill="${mainColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40"><circle cx="20" cy="20" opacity="1" r="20" /></svg>`);

    self.markerCluster = new MarkerClusterer(self.map, [], {
      styles: [{
        url: `data:image/svg+xml;base64,${svg}`,
        width: 40,
        height: 40,
        textColor: '#fff',
        textSize: '11',
      }],
    });

    const bounds = new google.maps.LatLngBounds();
    boundsCount = 0;
    self.mapReady();
  };

  this.addMarkers = function () {
    const bounds = new google.maps.LatLngBounds();
    for (let i = 0, l = stores.length; i < l; i++) {
      const currentStore = stores[i];
      const latitude = parseFloat(currentStore.latitude);
      const longitude = parseFloat(currentStore.longitude);
      const position = { lat: latitude, lng: longitude };
      const content = self.getInfoWindowContent(currentStore);

      const marker = new google.maps.Marker({
        // const marker = new google.maps.MarkerWithLabel({
        map: self.map,
        position,
        content,
        icon: markerIcon,
      });

      markers.push(marker);
      bounds.extend(new google.maps.LatLng(position));

      google.maps.event.addListener(marker, 'click', (function () {
        return function () {
          for (let j = 0; j < markers.length; j++) {
            markers[j].setIcon(markerIcon);
          }

          self.map.panTo(this.getPosition());
          self.createInfoWindow(this);
          this.setIcon(activeMarkerIcon);
        };
      }(marker)));

      google.maps.event.addListener(marker, 'mouseover', function () {
        // if (this.labelClass.indexOf('active') === -1) {
        this.setIcon(hoverMarkerIcon);
        // }
      });

      google.maps.event.addListener(marker, 'mouseout', function () {
        // if (this.labelClass.indexOf('active') === -1) {
        this.setIcon(markerIcon);
        // }
      });

      boundsCount++;
    }
    if (boundsCount > 1) {
      // get best fitbound from all markers
      this.map.fitBounds(bounds);
      self.markerCluster.addMarkers(markers);

      const { clusterIcon } = this;
      const { activeClusterIcon } = this;

      google.maps.event.addListener(self.markerCluster, 'mouseover', (cluster) => {
        cluster.clusterIcon_.div_.firstChild.src = activeClusterIcon;
      });

      google.maps.event.addListener(self.markerCluster, 'mouseout', (cluster) => {
        cluster.clusterIcon_.div_.firstChild.src = clusterIcon;
      });
    }
  };

  this.createInfoWindow = function (marker) {
    self.removeInfoWindow(markers);
    const windowContent = marker.content;
    const infoWindow = document.createElement('div');

    infoWindow.id = 'info-window';
    infoWindow.innerHTML = `<a href="#" class="btn btn-outline btn-circle btn--scale" id="close-info-window"></span><svg viewBox="0 0 24 24" class="icon"><use href="#icon-menu-close"></use></svg></a>${windowContent}`;
    document.getElementById('store-locator').appendChild(infoWindow);

    marker.setOptions({ labelClass: 'markerLabel active' });

    document.getElementById('close-info-window').addEventListener('click', (event) => {
      event.preventDefault();
      self.removeInfoWindow();
    });
  };

  this.removeInfoWindow = function () {
    if (document.getElementById('info-window')) {
      const element = document.getElementById('info-window');
      element.parentNode.removeChild(element);

      for (let j = 0; j < markers.length; j++) {
        markers[j].setIcon(markerIcon);
        markers[j].setOptions({ labelClass: 'markerLabel' });
      }
    }
  };

  this.getInfoWindowContent = function (store) {
    let content = ``;
    
    if (store.title) {
      content += `<div class="headline body-md">${store.title}</div>`;
    }

    if (store.address || store.zipcode || store.city || store.country) {
      content += '<ul class="address">';
        if (store.address) {
          content += `<li>${store.address}</li>`;
        }
        if (store.zipcode || store.city) {
          content += `<li>${store.zipcode}&nbsp;${store.city}</li>`;
        }
        if (store.country) {
          content += `<li>${store.country}</li>`;
        }
      content += `</ul>`;
    }

    if (store.phone || store.email || store.website) {
      content += '<div class="locator-contact">';
        if (store.phone) {
          content += `<a href="tel:${store.phone}" class="underline">${store.phone}</a><br/>`;
        }
        if (store.email) {
          content += `<a href="mailto:${store.email}" target="_blank" class="btn btn-outline btn-sm"><svg viewBox="0 0 24 24" class="icon"><use href="#icon-mail"></use></svg></a>`;
        }
        if (store.url) {
          content += `<a href="${store.url}" target="_blank" class="btn btn-outline btn-sm"><svg viewBox="0 0 24 24" class="icon"><use href="#icon-redirect"></use></svg></a>`;
        }
      content += '</div>';
    }
    return content;
  };

  this.init();
};

function initMap() {
  const datamap = document.querySelectorAll('[data-map]');
  if (datamap.length > 0) {
    window.map = new GMAP(datamap);
  }
}

window.initMap = initMap;
